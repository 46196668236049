import React, { useState } from 'react';
import Slider from 'react-input-slider';
// import './BetSliderComponent.scss'

function BetSliderComponent({canPlaceBet = true}) {
    const [state, setState] = useState({ x: 10 });
    let activeBackground = '#e0e9ee';
    let thumbBackground = '#e0e9ee'
    let cursor = {};
    if (canPlaceBet) {
      cursor = {
        cursor: 'pointer',
      } 
      activeBackground = 'linear-gradient(to left, #ffcd3c 100%, #ff9f1c);';
      thumbBackground = 'radial-gradient(circle at 48% 50%, #ffcd3c 0%, #ff9f1c 81%)';
    }
    return (
            <Slider
                className="bet__slider"
                styles={{
                    track: {
                      display: 'block',
                      ...cursor,
                      width: '100%',
                      backgroundColor: '#e0e9ee',
                      height: '19px',
                      borderRadius: '19px',
                    },
                    active: {
                      background: activeBackground,
                      height: '19px',
                      borderRadius: '19px',
                    },
                    thumb: {
                      width: 40,
                      height: 40,
                      marginTop: '-10px',
                      boxShadow: 0,
                      background: thumbBackground,
                    },
                    disabled: {
                      backgroundColor: '#e0e9ee',
                    }
                  }}
                axis="x"
                disabled={!canPlaceBet}
                x={state.x}
                onChange={({ x }) => setState(state => ({ ...state, x }))}
            />
    );
}

export default BetSliderComponent;
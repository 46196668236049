import React from 'react';
import './App.scss';
import BetSliderComponent from './components/BetSliderComponent/BetSliderComponent';
import { isMobile } from 'react-device-detect';


function App() {
  return (
    <div className="game">
      <header className="header">
        <div className="logo">
          <div className="logo__image"></div>
          { isMobile ? null : <p className="logo__text">liquid</p> }
        </div>
        <div className="blocks">
          <p className="blocks__title">blocks left</p>
          <p className="blocks__counter">45</p>
        </div>
        <p className="login">{isMobile ? 'Login' : 'Login with Scatter'}</p>
      </header>
      <div className="separator">
        <div className="game-stats">
          <div className="game-stats__info">
            <div className="game-stats__info-inner">
              <p className="game-stats__info-title">Players</p>     
              <p className="game-stats__players-count">520</p>
            </div>
            <div className="game-stats__info-inner">
              <p className="game-stats__info-title">Bet</p>      
              <p className="game-stats__bets-count">30</p>
            </div>
          </div>
          <div className="game-stats__info">
            <div className="game-stats__info-inner">
              <p className="game-stats__info-title">Players</p>     
              <p className="game-stats__players-count">9</p>
            </div>
            <div className="game-stats__info-inner">
              <p className="game-stats__info-title">Bet</p>      
              <p className="game-stats__bets-count">120</p>
            </div>
          </div>
        </div>
        <div className="game-visual">
          <div className="game-visual__balloon-left"></div>
          <div className="game-visual__balloon-right"></div>
        </div>
        <div className="bet-container">
          <div className="bet">
            <p className="bet__min-value">0.1 EOS</p>
            <BetSliderComponent />
            <p className="bet__max-value">500 EOS</p>
          </div>
        </div>
      </div>
      

      {/* popups */}
      <div className="choose-team">
        <p className="choose-team__title">Choose your<br/>team</p>
      </div>
    </div>
  );
}

export default App;
